import * as React from "react";
import {Component} from "react";
import NavigationSidebarLayout, {Navigation} from "components/NavigationSidebarLayout";
import {RouteComponentProps, Switch} from "react-router-dom";
import {instancesRouteLinks} from "areas/instances/instancesRouteLinks";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import {HostedInstanceResource} from "client/resources/hostedInstanceResource";
import repository from "client/repository";
import {InstanceDetail} from "areas/instances/Instances/InstanceDetail";
import RelatedBackgroundTasks from "shared/RelatedBackgroundTasks";
import InstanceSupportUsers from "areas/instances/Instances/InstanceSupportUsers";
import InstanceLiveInformation from "areas/instances/Instances/InstanceLiveInformation";
import InstanceUpgradeHistory from "areas/instances/Instances/InstanceUpgradeHistory";
import InstanceDiagnosticFiles from "./InstanceDiagnosticFiles";
import InstanceWorkers from "./InstanceWorkers";

type Props = RouteComponentProps<{ instanceId: string }>;

interface State {
    instance?: HostedInstanceResource;
}

class InstanceLayout extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        const instance = await repository.HostedInstances.get(this.props.match.params.instanceId);
        this.setState({instance});
    }

    render() {
        const instanceId = this.props.match.params.instanceId;
        const instance = this.state.instance;
        const instanceRoute = instancesRouteLinks.instance(instanceId);
        const instanceBreadcrumbTitle = instance && `${instance.DnsPrefix} (${instance.ShortId})`

        const navLinks = instance
            ? [
                Navigation.navItem("Detail", instanceRoute.root, true),
                Navigation.navItem("Tasks", instanceRoute.tasks, true),
                Navigation.navItem("Support Users", instanceRoute.supportUsers, true),
                Navigation.navItem("Live", instanceRoute.liveInformation, true),
                Navigation.navItem("Upgrade History", instanceRoute.upgradeHistory, true),
                Navigation.navItem("Diagnostic Files", instanceRoute.diagnosticFiles, true),
                Navigation.navItem("Workers", instanceRoute.workers, true)]
            : [];

        const content = <Switch>
            <ReloadableRoute path={instanceRoute.root} exact={true} render={_ => <InstanceDetail instanceId={instanceId} breadcrumbTitle={instanceBreadcrumbTitle} />}/>
            <ReloadableRoute path={instanceRoute.liveInformation} exact={true} render={_ => <InstanceLiveInformation instanceId={instanceId} breadcrumbTitle={instanceBreadcrumbTitle} />}/>
            <ReloadableRoute path={instanceRoute.supportUsers} exact={true} render={_ => <InstanceSupportUsers instanceId={instanceId} breadcrumbTitle={instanceBreadcrumbTitle} />}/>
            <ReloadableRoute path={instanceRoute.tasks} exact={true} render={_ => <RelatedBackgroundTasks documentId={instanceId} breadcrumbTitle={instanceBreadcrumbTitle} />}/>
            <ReloadableRoute path={instanceRoute.upgradeHistory} exact={true} render={_ => <InstanceUpgradeHistory instanceId={instanceId} breadcrumbTitle={instanceBreadcrumbTitle} />}/>
            <ReloadableRoute path={instanceRoute.diagnosticFiles} exact={true} render={_ => <InstanceDiagnosticFiles instanceId={instanceId} breadcrumbTitle={instanceBreadcrumbTitle} />}/>
            <ReloadableRoute path={instanceRoute.workers} exact={true} render={_ => <InstanceWorkers instanceId={instanceId} breadcrumbTitle={instanceBreadcrumbTitle} />}/>
        </Switch>;

        return <NavigationSidebarLayout navLinks={navLinks}
                                        content={content}
                                        preNavbarComponent={instance && instance.ShortId}
                                        name={instance && (instance.DnsPrefix)}
        />;
    }

}

export default InstanceLayout;